
import { getCurrentInstance } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import MainContent from '@/components/main-content/MainContent.vue';
import Loader from '@/components/loader/Loader.vue';
import { includeGigyaOidc } from '~/shared/utils';

export default defineComponent({
  components: {
    SectionContentContainer,
    MainContent,
    Loader,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;
    const gigyaKey = $config?.GIGYA_KEY;

    includeGigyaOidc(
      `https://cdns.gigya.com/JS/gigya.oidc.js?apiKey=${gigyaKey}`,
      'gigya-oidc-placeholder',
      siteUrl
    );
  },
  head: {
    title: 'Login proxy',
  },
});
