
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    textLabel: {
      type: String,
      default: '',
    },
    loaderSizeInPixels: {
      type: Number,
      default: 80,
    },
    textSizeAsClass: {
      type: String,
      default: 'text-sm',
    },
  },
  setup: (props) => {
    const loaderCircleStyles = {
      width: `${props.loaderSizeInPixels}px`,
      height: `${props.loaderSizeInPixels}px`,
    };

    return {
      loaderCircleStyles,
    };
  },
});
